<template lang="pug">
  b-modal(v-model='modalShowChangePassword', v-if='modalShowChangePassword' :hide-footer='true', :modal-class='[ "modal-change-password" ]', :centered='true', size='sm', title='Cambiar contraseña')
    b-form(@submit.prevent="procesarFormulario")
      b-form-group(label='Contraseña actual:' label-for='txtCurrentPassword')
        b-form-input(v-model='$v.txtCurrentPassword.$model' @blur="$v.txtCurrentPassword.$touch()" type='password')
        .error.text-left(v-if="(!$v.txtCurrentPassword.required && $v.txtCurrentPassword.$anyDirty)") Campo obligatorio
        .error.text-left(v-if="(!$v.txtCurrentPassword.isCorrect && $v.txtCurrentPassword.$anyDirty)") La contraseña ingresada no coincide con la actual.
      hr
      b-form-group(label='Nueva contraseña:' label-for='txtPassword')
        b-form-input(v-model='$v.txtPassword.$model' type='password')
        .error.text-left(v-if="(!$v.txtPassword.required && $v.txtPassword.$anyDirty)") Campo obligatorio
        .error.text-left(v-if="(!$v.txtPassword.minLength && $v.txtPassword.$anyDirty)") Ingrese como mínimo 8 dígitos.
      b-form-group(label='Confirmar nueva contraseña:' label-for='txtConfirmPassword')
        b-form-input(v-model='$v.txtConfirmPassword.$model' type='password')
        .error.text-left(v-if="(!$v.txtConfirmPassword.required && $v.txtConfirmPassword.$anyDirty)") Campo obligatorio
        .error.text-left(v-if="(!$v.txtConfirmPassword.sameAsPassword && $v.txtConfirmPassword.$anyDirty)") Las contraseñas deben coincidir
      .text-right
        b-button(type='button' @click='close()' variant='default') Cancelar
        b-button(type='submit' variant='primary') Continuar
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
export default {
  name: 'modalChangePassword',
  data () {
    return {
      modalShowChangePassword: false,
      txtCurrentPassword: '',
      txtPassword: '',
      txtConfirmPassword: ''
    }
  },
  validations: {
    txtCurrentPassword: {
      required,
      isCorrect(pass) {
        if (pass === '') return true
        return this.validatePassword()
      }
    },
    txtPassword: {
      required,
      minLength: minLength(8)
    },
    txtConfirmPassword: {
      required,
      sameAsPassword: sameAs('txtPassword')
    }
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    ...mapActions(['ChangePassword', 'ValidatePassword']),
    procesarFormulario () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        console.log('Validar formulario')
      } else {
        this.ChagePasswordService()
      }
    },
    async validatePassword () {
      try {
        let dto = {
          codigo_cliente: this.user.ID_CLIENTE,
          contrasena_antigua: this.txtCurrentPassword
        }
        let res = await this.ValidatePassword(dto)
        return res.status === 200
      } catch (error) {
        console.log(error)
      }
    },
    async ChagePasswordService () {
      const _this = this
      try {
        await this.ChangePassword({ pwd: this.txtPassword })
          .then((res) => {
            console.log(res)
            if (res.status === 200) {
              _this.close()
              _this.$bvModal.msgBoxOk('Cambio de contraseña exitoso', {
                title: 'Hunter',
                centered: true
              })
                .then(value => {
                  _this.txtPassword = ''
                  _this.txtConfirmPassword = ''
                })
                .catch(err => {
                  console.log(err)
                })
            }
          })
          .catch(err => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    open () {
      this.modalShowChangePassword = true
    },
    close () {
      this.modalShowChangePassword = false
      this.$v.$reset()
    }
  }
}
</script>

<style lang="scss">
  @import './style.scss';
</style>
