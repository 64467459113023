import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'vehiculos',
    component: () => import(/* webpackChunkName: "Vehiculos" */ '../views/Home.vue'),
    meta: { protectedRoute: true }
  },
  {
    path: '/productos/:id',
    name: 'productos',
    component: () => import(/* webpackChunkName: "products" */ '../views/Products.vue'),
    meta: { protectedRoute: true }
  },
  {
    path: '/perfil',
    name: 'perfil',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
    meta: { protectedRoute: true }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/PageNotFound.vue'),
    meta: { protectedRoute: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   if (to.meta.protectedRoute) {
//     if (localStorage.getItem('usuario')) {
//       next()
//     } else {
//       next('/login')
//     }
//   } else {
//     next()
//   }
// })

export default router
