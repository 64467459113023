import configService from './config'

const services = {
  GetToken: configService.apiUrl + '/autenticarToken',
  Login: configService.apiUrl + '/loginClienteExtranet',
  GetUser: configService.apiUrl + '/listarDatosUsuario',
  UpdateUser: configService.apiUrl + '/actualizarDatos',
  GetListVehicle: configService.apiUrl + '/misVehiculos',
  EditLicense: configService.apiUrl + '/registraActualizacionVehiculo',
  GetProductsByVehicle: configService.apiUrl + '/ProductosVehiclesExtranet',
  ChangePassword: configService.apiUrl + '/CambiarContraExtranet',
  paymentService: configService.apiUrlFormat + '/metodoPagoIzipay',
  SendMail: configService.apiUrl + '/ProductoVehicleEnvioCorreosExtranet',
  encryptDocument: configService.apiUrlFormat + '/encriptarOs',
  getCertificated: configService.apiUrlFormat,
  checkoutVehicle: configService.apiUrl + '/solicitudRevisionExtranet',
  GetListOfVoucher: configService.apiUrl + '/listaComprobanteByOS',
  GetVoucher: configService.apiUrlFin + '/postApiTokenAuthEFACT',
  validatePassword: configService.apiUrl + '/validaClaveLoginExtranet',
  GetRequest: configService.apiUrlRec + '/registraReclamo',
  RequestVehicleReview: configService.apiUrl + '/solicitudRevisionExtranet'
}

export default services
