<template lang="pug">
  b-modal(v-model='modalShowFaqs', :hide-footer='true', :modal-class='[ "modal-faqs" ]', :centered='true', size='lg', title='Preguntas Frecuentes')
    .modal-faqs-container
        ul
            li
                h4 ¿Por qué es importante actualizar mis datos de contacto?
                p Es importante actualizar tus datos de contacto porque te permitirá recibir información relevante de tu servicio, atenciones en caso de emergencias por robo vehicular y otras atenciones. Por tu seguridad y privacidad, los datos que registras en el sistema deben ser verificados antes de cada atención.
            li
                h4 ¿Cómo puedo actualizar mis datos?
                p Desde 
                  a(href="https://www.hunterlojack.com/web/" target="_blank") nuestra página web
                  | , accediendo a la opción
                  strong   Actualizar Tus Datos
                  | , o comunicándote con nuestra Central de Servicio al Cliente,
                  strong  6185930 opción 2.
            li
                h4 ¿Cómo puedo actualizar o registrar mi placa?
                p Puedes actualizar o registrar tu placa desde 
                  a(href="https://www.hunterlojack.com/web/" target="_blank") nuestra página web
                  | , ingresando a la opción 
                  strong  Mis Vehículos
                  |  y seleccionando el icono . También puedes hacerlo comunicándote con nuestra Central de Servicio al Cliente,
                  strong  6185930 opción 2.
            li
                h4 ¿Cada cuánto debo realizar el chequeo a mi dispositivo de GPS/LOJACK? ¿Por qué es importante?
                p Debes realizar el mantenimiento del dispositivo:
                  ol.sub_list
                    li
                      p Cada 6 meses
                    li
                      p En cada renovación de tu servicio
                    li
                      p Cuando realices reparaciones, instalaciones, modificaciones al sistema eléctrico de tu vehículo e instalaciones de gas natural – GNV
                    li
                      p En caso de haber sufrido un choque vehicular. Es muy importante a fin de poder garantizar el buen funcionamiento de tu dispositivo
                        strong  GPS/LOJACK.
            li
                h4 ¿Cómo debo solicitar la atención del chequeo?
                p Por medio de 
                  a(href="https://www.hunterlojack.com/web/" target="_blank") nuestra página web
                  | , accediendo a la opción Solicitar Chequeo, o a través del correo:
                  a  atencionalcliente@hunterlojack.com.
            li
                h4 ¿Cuáles son las modalidades de atención para realizar el chequeo?
                p Las modalidades de atención son dos:
                ul.sub_list
                  li
                    p
                      strong Remota:
                      |  Si cuentas con un servicio de GPS y el dispositivo permite enlazarnos remotamente, se puede proceder a realizar el chequeo virtual con un personal especializado.
                  li
                    p
                      strong Presencial:
                      |  Si cuentas con el dispositivo LOJACK, o si el dispositivo GPS no permite realizar la atención virtual, deberás acercarte a nuestras oficinas de Atención al Cliente ubicadas en Jean Paul Sartre 170 Surquillo, coordinando previamente una cita.
            li
                h4 ¿Cómo solicito la cita presencial para el chequeo del dispositivo?
                p Comunicándote con nuestra Central de Servicio al Cliente: 6185930 Opc. 0 o a través de nuestro correo atencionalcliente@hunterlojack.com.
            li
                h4 ¿Cómo puedo obtener la documentación de mi servicio?
                p Desde 
                  a(href="https://www.hunterlojack.com/web/" target="_blank") nuestra página web
                  | ; solo tienes que ingresar a la opción
                  strong  Mis Vehículos/Productos/
                  |  Ver documentos y podrás visualizar y descargar la siguiente información de tu servicio:
                ul.sub_list
                  li
                    p - Cartilla de Seguridad
                  li
                    p - Información de Chequeos
                  li
                    p - Comprobante de Pago
                  li
                    p - Certificado de Servicio
                  li
                    p - Condiciones de Servicio
            li
                h4 Necesito hacer cambio de propietario ¿Cómo ingreso mi solicitud?
                p Para proceder con el cambio de propietario el vehículo deberá estar registrado en SUNARP a nombre del solicitante, caso contrario debes contar con el acta de transferencia vehicular expedida por notario público para acreditar la titularidad del vehículo; en ambos casos puedes acercarte a nuestras oficinas de Atención al Cliente, ubicadas en Jean Paul Sartre 170 Surquillo, o enviar el requerimiento al correo de
                  a  atencionalcliente@hunterlojack.com
            li
                h4 Voy a vender mi auto y necesito trasladar el dispositivo de GPS/LOJACK a otra unidad de mi propiedad ¿Qué debo hacer?
                p Debes solicitar una cita para la desinstalación de tu dispositivo GPS/LOJACK. Una vez desinstalado, para la reinstalación en la nueva unidad debes comunicarte a nuestra Central de Servicio al Cliente, 6185930 opción 0 y solicitar la reinstalación de tu dispositivo GPS/LOJACK.
            li
                h4 Tengo consultas sobre el aplicativo
                p Para información sobre el uso del aplicativo, accesos y reportes de plataforma puedes comunicarte nuestra Central de Soporte Técnico 618 5939 que atiende 24/7, o envíanos un correo con tu consulta a
                  a  soportetecnico@hunterlojack.com.
        .text-right
            b-button(type='submit' @click='close()'  variant='primary') Entiendo
</template>

<script>
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

export default {
  name: 'modalFaqs',
  data () {
    return {
      modalShowFaqs: false
    }
  },
  computed: {
    faQuestionCircleIcon () {
      return faQuestionCircle
    }
  },
  methods: {
    open () {
      this.modalShowFaqs = true
    },
    close () {
      this.modalShowFaqs = false
    }
  }
}
</script>

<style lang="scss">
  @import './style.scss';
</style>
