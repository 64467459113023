<template lang="pug">
  b-modal(v-model='modalShow', :hide-footer='true', :modal-class='[ "modal-carousel" ]', :centered='true', size='lg')
    carousel(:items='1', :autoplay='false', :pullDrag='true', :nav="false", :autoHeight='false', v-if='carouselShow')
      img(:src="Banner1")
      img(:src="Banner3")
</template>

<script>
import carousel from 'vue-owl-carousel'
import FirstBanner from '../../assets/images/banner1.png'
import SecondBanner from '../../assets/images/banner2.jpg'
import ThirdBanner from '../../assets/images/banner3.png'
export default {
  name: 'CarouselModal',
  components: { carousel },
  data () {
    return {
      modalShow: false,
      Banner1: FirstBanner,
      Banner2: SecondBanner,
      Banner3: ThirdBanner,
      carouselShow: false
    }
  },
  methods: {
    open () {
      this.modalShow = true
    },
    close () {
      this.modalShow = false
    }
  },
  mounted () {
    setTimeout(() => {
      this.carouselShow = true
    }, 4000)
  }
}
</script>

<style lang="scss">
  .modal-carousel {
    .modal-content {
      background-color: transparent;
      border: 0;
    }
    header {
      padding: 0;
      button {
        background: transparent;
        box-shadow: 0 0 0 0;
        border: 0;
        font-size: 30px;
        color: #fff;
        padding: 10px 15px;
        height: auto;
      }
    }
    .owl-dot.active span {
      background-color: #FF4958 !important;
    }
  }
</style>
