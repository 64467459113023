import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Vuelidate from 'vuelidate'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import SideBar from '@/components/sidebar'
import SocialBar from '@/components/socialBar'
import FooterBar from '@/components/footerBar'
import AlertBar from '@/components/alertBar'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/scss/style.scss'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuelidate)
Vue.use(VueMoment, {
  moment
})

Vue.component('alert-bar', AlertBar)
Vue.component('footer-bar', FooterBar)
Vue.component('side-bar', SideBar)
Vue.component('social-bar', SocialBar)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
