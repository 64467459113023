<template lang="pug">
  b-modal(v-model='modalShowContact', :hide-footer='true', :modal-class='[ "modal-contact" ]', :centered='true', size='xs', title='Contáctanos a los siguientes números de atención')
    .modal-contact-container
        .text-left
          .contact-item
            h5  Central de Servicio al Cliente:
            p
              a(href="tel:016185930") (01) 618-5930 Opc. 0 
            a.email(href="mailto:atencionalcliente@hunterlojack.com") atencionalcliente@hunterlojack.com 
            span (Atención: Lun-Vie 8:30am a 6pm / Sab 9:00am a 12pm)
            br
            p
              a(href="https://wa.me/+51989306440" target="_blank") WhatsApp: 989306440
          .contact-item
            h5 Central de emergencia
            p 
              a(href="tel:016185930") (01) 612-2222
            
            span (Atención 24 horas)
          .contact-item
            h5 Soporte Técnico
            p
              a(href="tel:016122222") (01) 618-5939
            a.email(href="mailto:soportetecnico@hunterlojack.com") soportetecnico@hunterlojack.com 
            span (Atención 24 horas)
</template>

<script>
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

export default {
  name: 'modalContact',
  data () {
    return {
      modalShowContact: false
    }
  },
  computed: {
    faQuestionCircleIcon () {
      return faQuestionCircle
    }
  },
  methods: {
    open () {
      this.modalShowContact = true
    },
    close () {
      this.modalShowContact = false
    }
  }
}
</script>

<style lang="scss">
  @import './style.scss';
</style>
