const serverUris = {
  development: 'https://sys.hunterlojack.com/SIT',
  developmentFormat: 'https://sys.hunterlojack.com/Formatos',
  developmentFin: 'https://sys.hunterlojack.com/FIN',
  developmentREC: 'https://sys.hunterlojack.com/REC',
  production: 'https://sys.hunterlojack.com/SIT'
}
const configService = {
  apiUrl: serverUris.development,
  apiUrlFormat: serverUris.developmentFormat,
  apiUrlFin: serverUris.developmentFin,
  apiUrlRec: serverUris.developmentREC
}

export default configService
