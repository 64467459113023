<template lang="pug">
#app
  social-bar
  .content-wrapper(:class='{ sidebarSpace: userAuthenticated }')
    router-view
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['alertBar', 'user']),
    ...mapGetters(['userAuthenticated'])
  },
  methods: {
    ...mapActions(['GetUser']),
    getUserAuthenticated () {
      if (localStorage.getItem('tokenUser')) {
        const accessToken = JSON.parse(localStorage.getItem('tokenUser'))
        console.log(accessToken.resultado.ID)
        const ID = accessToken ? accessToken.resultado.ID : null
        this.GetUser(ID)
      } else {
        this.$router.push('/login')
      }
    }
  },
  created () {
    this.getUserAuthenticated()
  }
}
</script>
