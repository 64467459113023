<template lang="pug">
  #alertBar
    .alertBar-container(v-if="!whitUpdateData && !withoutEmail")
      .alertBar(:class='whitUpdateData', v-if='whitUpdateData')
        p Tus datos no han sido actualizados desde hace más de 2 meses. Actualizalos para acceder a grandes beneficios.
        .action-bar
          p.text-right
            | Actualiza tus datos.
            router-link(to='/perfil') Haz click aquí.
      .alertBar(:class='{ "warning" : withoutEmail}', v-if='!withoutEmail')
        p Tu correo electrónico nos ayudará a manternernos más cerca de ti.
        .action-bar
          p.text-right
            | Completa tu correo.
            router-link(to='/perfil') Haz click aquí.
      //- .alertBar.info(v-if='!whitUpdateData')
      //-   p Tus datos están actualizados
      //-   .action-bar
      //-     p.text-right
      //-       a ¡Gracias!
      //- .alertBar.info(v-if='withoutEmail')
      //-   p Tu correo está actualizado
      //-   .action-bar
      //-     p.text-right
      //-       a ¡Gracias!
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'AlertBar',
  computed: {
    ...mapState(['user']),
    whitUpdateData () {
      if (this.user?.FECHA_ACTUALIZACION) {
        let diff = this.$moment(new Date()).diff(this.$moment(this.user.FECHA_ACTUALIZACION), 'months', true)
        console.log(diff)
        return diff >= 2
      }
    },
    withoutEmail () {
      if (this.user?.CORREO) {
        return this.user.CORREO.length != 0
      }
      return false
    }
  }
}
</script>

<style lang="scss">
  @import './style.scss';
</style>
