<template lang="pug">
  .footerBar
    .footerBar-container
      ul.nro-contactos
        li.icon
          font-awesome-icon(:icon="faPhoneIcon")
        li
          p Central de Emergencia:
            span 621-2222
        li
          p Soporte Técnico:
            span 618-5939
        li
          p Servicio al cliente:
            span 618-5930
      ul.social.float-right
        li
          p
            strong Siguenos en:
        li
          a(href="https://www.facebook.com/hunterlojack" target="_blank")
            img(:src="iconFB")
        li
          a(href="https://www.instagram.com/hunterperu/?hl=es-la" target="_blank")
            img(:src="iconIG")
        li
          a(href="https://www.linkedin.com/company/hunter-lojack" target="_blank")
            img(:src="iconIN")
        li
          a(href="https://www.youtube.com/channel/UCjjtexAP4K1k-l0vZOSIvzA" target="_blank")
            img(:src="iconYT")
</template>

<script>
import { faPhoneVolume } from '@fortawesome/free-solid-svg-icons'
import iconFB from '@/assets/images/icon-svg-fb.svg'
import iconIG from '@/assets/images/icon-svg-ig.svg'
import iconIN from '@/assets/images/icon-svg-ln.svg'
import iconYT from '@/assets/images/icon-svg-yt.svg'
export default {
  name: 'footerBar',
  data () {
    return {
      iconFB: iconFB,
      iconIG: iconIG,
      iconIN: iconIN,
      iconYT: iconYT
    }
  },
  computed: {
    faPhoneIcon () {
      return faPhoneVolume
    }
  }
}
</script>

<style lang="scss">
  @import './style.scss';
</style>
