import Vue from 'vue'
import Vuex from 'vuex'
import ServiceHunter from '../api/services'
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    vehicles: [],
    alertBar: false,
    auth: false
  },
  getters: {
    userAuthenticated (state) {
      return !!state.user
    }
  },
  mutations: {
    Logout (state) {
      state.user = false
      state.auth = false
    },
    setVehicles (state, payload) {
      state.vehicles = payload
    },
    setUser (state, payload) {
      state.user = payload
    },
    updateLocalUser (state, payload) {
      state.user.APELLIDO_PATERNO = payload.APELLIDO_PATERNO
      state.user.APELLIDO_MATERNO = payload.APELLIDO_MATERNO
      state.user.PRIMER_NOMBRE = payload.PRIMER_NOMBRE
      state.user.SEGUNDO_NOMBRE = payload.SEGUNDO_NOMBRE
      state.user.FECHA_NACIMIENTO = payload.FECHA_NACIMIENTO
      state.user.CORREO = payload.CORREO
      state.user.TELEFONO = payload.TELEFONO
    }
  },
  actions: {
    Logout ({ commit }) {
      commit('setUser', null)
      localStorage.removeItem('usuario')
      localStorage.removeItem('tokenUser')
      router.push('/login')
    },
    async Login ({ commit, dispatch }, data) {
      try {
        const res = await ServiceHunter.Login(data)
        if (res.status === 200) {
          localStorage.setItem('tokenUser', JSON.stringify(res.data))
          dispatch('GetUser', res.data.resultado.ID)
          router.push('/')
        }
      } catch (error) {
        console.log(error)
        return 'error'
      }
    },
    async GetUser ({ commit, dispatch }, data) {
      try {
        const res = await ServiceHunter.GetUser(data)
        if (res.status === 200) {
          // res.data.resultado[0].EMAIL = "gjarias09@l.com"
          commit('setUser', res.data.resultado[0])
          localStorage.setItem('usuario', JSON.stringify(res.data.resultado))
        } else {
          router.push('/login')
        }
      } catch (error) {
        router.push('/login')
        console.log(error)
      }
    },
    async UpdateUser ({ commit, state }, data) {
      try {
        data.ID_CLIENTE = state.user.ID_CLIENTE
        const res = await ServiceHunter.UpdateUser(data)
        console.log(res)
        if (res.status === 200) {
          return res
        }
      } catch (error) {
        console.log(error)
      }
    },
    async GetListVehicle ({ commit, state }) {
      try {
        const id = state.user.ID_CLIENTE
        const res = await ServiceHunter.GetListVehicle(id)
        if (res.data.status === 'success') {
          commit('setVehicles', res.data.registros)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async CheckoutVehicle ({ commit, state }, data) {
      try {
        data.id = state.user.ID_CLIENTE
        const res = await ServiceHunter.CheckoutVehicle(data)
        if (res.status === 200) {
          return res
        }
      } catch (error) {
        console.log(error)
      }
    },
    async GetProductsByVehicle ({ commit, state }, idVehiculo) {
      try {
        const data = {
          id: state.user.ID_CLIENTE,
          idVehiculo: idVehiculo
        }
        const res = await ServiceHunter.GetProductsByVehicle(data)
        if (res.status === 200) {
          return res.data.resultado
        }
      } catch (error) {
        console.log(error)
      }
    },
    async EditLicense ({ commit, state }, data) {
      try {
        data.codigo_usuario = state.user.ID_CLIENTE
        const res = await ServiceHunter.EditLicense(data)
        if (res.status === 200) {
          return res
        }
      } catch (error) {
        console.log(error)
      }
    },
    async ChangePassword ({ commit, state }, data) {
      try {
        data.id = state.user.ID_CLIENTE
        return await ServiceHunter.ChangePassword(data)
      } catch (error) {
        console.log(error)
      }
    },
    async ValidatePassword ({ commit, state }, data) {
      try {
        return await ServiceHunter.ValidatePassword(data)
      } catch (error) {
        console.log(error)
      }
    },
    async PaymentService ({ commit, state}, data) {
      try {
        const res = await ServiceHunter.PaymentService(data)
        if(res.status == 200) {
          return res.data
        }
        return res
      } catch(error) {
        console.log(error)
      }
    },
    async SendMail ({ commit, state }, data) {
			try {
				data.id = state.user.ID_CLIENTE
				const res = await ServiceHunter.SendMail(data)
				if (res.status === 200) {
					return res.data
				}
			} catch (error) {
				console.log(error)
			}
		},
    async GenerateDocument ({ commit, dispatch }, data) {
      try {
        const res = await ServiceHunter.EncryptDocument(data.os)
        if (res.status === 200) {
          return res.data
				}
      } catch (error) {
        console.log(error)
      }
    },
    async GetCertificated (state, data) {
      try {
        const res = await ServiceHunter.GetCertificated(data)
        if (res.status === 200) {
          return res
				}
      } catch (error) {
        console.log(error)
      }
    },
    async GetListOfVoucher (state, data) {
      try {
        const res = await ServiceHunter.GetListOfVoucher(data)
        if (res.status === 200) {
          return res.data
        }
        
      } catch (error) {
        console.log(error)
      }
    },
    async GetVoucher (state, data) {
      try {
        const res = await ServiceHunter.GetVoucher(data)
        if (res.status === 200) {
          return res
				}
      } catch (error) {
        console.log(error)
      }
    },
    async GetRequest (state, data) {
      try {
        const res = await ServiceHunter.GetRequest(data)
        if (res.status === 200) {
          return res
				}
      } catch (error) {
        console.log(error)
      }
    },
    async RequestVehicleReview (state, data) {
      try {
        const res = await ServiceHunter.RequestVehicleReview(data)
        if (res.status === 200) {
          return res
				}
      } catch (error) {
        console.log(error)
      }
    },
    async LoadUserLocalStorage ({ commit }) {
      if (!localStorage.getItem('usuario')) {
        commit('setUser', JSON.parse(localStorage.getItem('usuario')))
        return true
      }
    }
  },
  modules: {
  }
})
