<template lang="pug">
    aside#sidebar.skin-black(:class='{ active: !collapse }')
      carousel-items(ref='ModalCarousel')
      change-password(ref='ModalChangePassword')
      faqs-modal(ref='ModalFaqs')
      contact-modal(ref='ModalContact')
      .mobile-menu
        .icon-toggle(@click="toggleMenu")
          font-awesome-icon(:icon="collapse ? faBarsIcon :  faTimesIcon")
          img(:src='logoFull')
      section.sidebar
        .logo-panel
          img(:src='logo')
        router-link(to="/perfil")
          .user-panel(v-if='user')
            .pull-left.image
              img.img-circle(:src='UserImg' alt='User Image')
            .pull-left.info(v-b-tooltip.hover.right title="Ver/Editar mis datos")
              p {{ user.PRIMER_NOMBRE }} {{ user.APELLIDO_PATERNO }}
              span
                |  Conectado
        ul.sidebar-menu
          li.header NAVEGACIÓN
          li
            router-link(to="/")
              font-awesome-icon(:icon="carIcon")
              span Mis vehículos
        ul.sidebar-menu-footer
          li.footer
            .btn-redirect(@click='showChangePassword')
              font-awesome-icon(:icon="faLockIcon")
              span Cambiar contraseña
          //- li.footer
          //-   .btn-redirect(@click='showCampaings')
          //-     font-awesome-icon(:icon="faBellIcon")
          //-     span Campañas
          //-     .badge 1
          li.footer
            .btn-redirect(@click='showFaqs')
              font-awesome-icon(:icon="faQuestionCircleIcon")
              span Preguntas frecuentes
          li.footer
            .btn-redirect(@click='showContact')
              font-awesome-icon(:icon="faHeadsetIcon")
              span Contacto
          li.footer
            .btn-redirect(@click='Logout')
              font-awesome-icon(:icon="faDoorOpenIcon")
              span Salir
          //- li.footer
          //-   a(href='https://www.hunterlojack.com/' target='_blank')
          //-     font-awesome-icon(:icon="faGlobeAmericasIcon")
          //-     span Sitio web
</template>

<script>
import { mapState, mapActions } from 'vuex'
import LogoBlanco from '../../assets/images/logo-blanco-color.png'
import LogoFull from '../../assets/images/logo-full-color.png'
import UserImg from '../../assets/images/user.jpeg'
import { faQuestionCircle, faHeadset, faCar, faUserAlt, faSignOutAlt, faGlobeAmericas, faBell, faBars, faTimes, faLock } from '@fortawesome/free-solid-svg-icons'

import Carousel from '../carousel'
import ModalChangePassword from '../changePassword'
import ModalFaqs from '../faqs'
import ModalContact from '../contact'

export default {
  name: 'SideBar',
  data () {
    return {
      logo: LogoBlanco,
      logoFull: LogoFull,
      UserImg,
      collapse: true
    }
  },
  components: {
    'carousel-items': Carousel,
    'change-password': ModalChangePassword,
    'faqs-modal': ModalFaqs,
    'contact-modal': ModalContact
  },
  computed: {
    ...mapState(['user']),
    carIcon () {
      return faCar
    },
    userIcon () {
      return faUserAlt
    },
    faDoorOpenIcon () {
      return faSignOutAlt
    },
    faGlobeAmericasIcon () {
      return faGlobeAmericas
    },
    faBellIcon () {
      return faBell
    },
    faBarsIcon () {
      return faBars
    },
    faTimesIcon () {
      return faTimes
    },
    faLockIcon () {
      return faLock
    },
    faHeadsetIcon () {
      return faHeadset
    },
    faQuestionCircleIcon () {
      return faQuestionCircle
    }
  },
  methods: {
    ...mapActions(['Logout']),
    showChangePassword () {
      this.$refs.ModalChangePassword.open()
    },
    showCampaings () {
      this.$refs.ModalCarousel.open()
    },
    showFaqs () {
      this.$refs.ModalFaqs.open()
    },
    showContact () {
      this.$refs.ModalContact.open()
    },
    toggleMenu () {
      this.collapse = !this.collapse
    }
  }
}
</script>

<style lang="scss">
  @import './style.scss';
</style>
