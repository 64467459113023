import axios from 'axios'
import services from './methods.js'

const getToken = () => {
  const accessToken = JSON.parse(localStorage.getItem('tokenUser'))
  return accessToken.token
}

const ServiceHunter = {}

ServiceHunter.GetToken = () => {
  return axios.post(services.GetToken, {
    usuario: 'hunt3er',
    contrasena: 'Nt8@_P7P3RuH$ter'
  })
}

ServiceHunter.Login = (data) => {
  return axios.post(services.Login, data, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

ServiceHunter.GetUser = (data) => {
  return axios.post(services.GetUser, {
    id: data
  }, {
    headers: {
      'Content-Type': 'application/json',
      token: `${getToken()}`
    }
  })
}

ServiceHunter.UpdateUser = (data) => {
  return axios.post(services.UpdateUser, data, {
    headers: {
      'Content-Type': 'application/json',
      token: `${getToken()}`
    }
  })
}

ServiceHunter.GetListVehicle = (id) => {
  return axios.get(services.GetListVehicle + `/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      token: `${getToken()}`
    }
  })
}

ServiceHunter.EditLicense = (data) => {
  return axios.post(services.EditLicense, data, {
    headers: {
      'Content-Type': 'application/json',
      token: `${getToken()}`
    }
  })
}

ServiceHunter.GetProductsByVehicle = (data) => {
  return axios.post(services.GetProductsByVehicle, data, {
    headers: {
      'Content-Type': 'application/json',
      token: `${getToken()}`
    }
  })
}

ServiceHunter.ChangePassword = (data) => {
  return axios.post(services.ChangePassword, data, {
    headers: {
      'Content-Type': 'application/json',
      token: `${getToken()}`
    }
  })
}

ServiceHunter.ValidatePassword = (data) => {
  return axios.post(services.validatePassword, data, {
    headers: {
      'Content-Type': 'application/json',
      token: `${getToken()}`
    }
  })
}

ServiceHunter.PaymentService = (data) => {
  return axios.post(services.paymentService, data, {
    headers: {
      'Content-Type': 'application/json',
      token: `${getToken()}`
    }
  })
}

ServiceHunter.SendMail = (data) => {
  return axios.post(services.SendMail, data, {
    headers: {
      'Content-Type': 'application/json',
      token: `${getToken()}`
    }
  })
}

ServiceHunter.EncryptDocument = (data) => {
  return axios.get(services.encryptDocument + `/${data}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

ServiceHunter.GetCertificated = (data) => {
  return axios.get(services.getCertificated + `/${data.name}` + `/${data.os}`, {
    headers: {
      'Content-Type': 'application/json'
    },
    responseType: 'blob',
  })
}

ServiceHunter.CheckoutVehicle = (data) => {
  return axios.post(services.checkoutVehicle, data, {
    headers: {
      'Content-Type': 'application/json',
      token: `${getToken()}`
    }
  })
}

ServiceHunter.GetListOfVoucher = (data) => {
  return axios.post(services.GetListOfVoucher, data, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

ServiceHunter.GetVoucher = (os) => {
  return axios.get(services.GetVoucher + `/${os}`, {
    headers: {
      'Content-Type': 'application/json'
    },
    responseType: 'blob',
  })
}

ServiceHunter.GetRequest = (data) => {
  return axios.post(services.GetRequest, data, {
    headers: {
      'Content-Type': 'application/json',
      token: `${getToken()}`
    }
  })
}

ServiceHunter.RequestVehicleReview = (data) => {
  return axios.post(services.RequestVehicleReview, data, {
    headers: {
      'Content-Type': 'application/json',
      token: `${getToken()}`
    }
  })
}

export default ServiceHunter
