<template lang="pug">
    .social-bar
      ul
        li
          a(href="https://www.facebook.com/hunterlojack" target="_blank")
            img(:src="iconFB")
        li
          a(href="https://www.instagram.com/hunterperu/?hl=es-la" target="_blank")
            img(:src="iconIG")
        li
          a(href="https://www.linkedin.com/company/hunter-lojack" target="_blank")
            img(:src="iconIN")
        li
          a(href="https://www.youtube.com/channel/UCjjtexAP4K1k-l0vZOSIvzA" target="_blank")
            img(:src="iconYT")
</template>

<script>
import { faPhoneVolume } from '@fortawesome/free-solid-svg-icons'
import iconFB from '@/assets/images/facebook-square-brands.svg'
import iconIG from '@/assets/images/instagram-brands.svg'
import iconIN from '@/assets/images/linkedin-brands.svg'
import iconYT from '@/assets/images/youtube-square-brands.svg'
export default {
  name: 'footerBar',
  data () {
    return {
      iconFB: iconFB,
      iconIG: iconIG,
      iconIN: iconIN,
      iconYT: iconYT
    }
  },
  components: {
    iconFB
  },
  computed: {
    faPhoneIcon () {
      return faPhoneVolume
    }
  }
}
</script>

<style lang="scss">
  @import './style.scss';
</style>
